//引入Vue
import Vue from 'vue'
//引入App
import App from './App.vue'
//引入VueRouter
import VueRouter from 'vue-router'
//引入路由器
import router from './router'
//引入store
import store from './store'
//完整引入
//引入ElementUI组件库
import ElementUI from 'element-ui';
//引入ElementUI全部样式
import 'element-ui/lib/theme-chalk/index.css';
// 弹幕插件
import vueDanmaku from 'vue-danmaku'
// 富文本编辑器
import mavonEditor from 'mavon-editor';
import 'mavon-editor/dist/css/index.css';
import './assets/css/markdown-highlight.css'
import './assets/css/tocbot.css'

import common from './utils/common'
import constant from './utils/constant'

Vue.prototype.$common = common
Vue.prototype.$constant = constant


Vue.config.productionTip = false

Vue.use(vueDanmaku)
Vue.use(VueRouter)
Vue.use(ElementUI);
Vue.use(mavonEditor);

new Vue({
  render: h => h(App),
  store,
  router: router,
}).$mount('#app')
